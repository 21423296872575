var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var dialog = ref.on
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var tooltip = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.taskDialog = true
                                    }
                                  }
                                },
                                Object.assign({}, tooltip, dialog)
                              ),
                              [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    { attrs: { dark: "" } },
                                    Object.assign({}, tooltip)
                                  ),
                                  [_vm._v("zoom_in")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [_c("span", [_vm._v("Show task outcome")])]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.taskDialog,
        callback: function($$v) {
          _vm.taskDialog = $$v
        },
        expression: "taskDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "primary--text" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v("mdi-information-variant")
                ]),
                _vm._v("Task outcome")
              ],
              1
            )
          ]),
          _c("v-divider", { staticClass: "mb-1" }),
          _c(
            "v-card-text",
            [
              _c("v-card-text", [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } })
              ])
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      _vm.taskDialog = false
                    }
                  }
                },
                [_vm._v("close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }