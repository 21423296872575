<template>
    <v-dialog v-model="taskDialog" max-width="700">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn color="primary" icon @click="taskDialog = true" v-on="{ ...tooltip, ...dialog }">
                        <v-icon dark v-on="{ ...tooltip }">zoom_in</v-icon>
                    </v-btn>
                </template>
                <span>Show task outcome</span>
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title>
                <div class="primary--text"><v-icon class="mr-1">mdi-information-variant</v-icon>Task outcome</div>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text>
                <v-card-text>
                    <div v-html="message"></div>
                </v-card-text>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="taskDialog = false">close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'UserTaskOutcome',
    props: {
        message: String
    },
    data() {
        return {
            taskDialog: false
        }
    }
}
</script>
